<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-8 offset-xl-2 col-lg-8 col-md-8 offset-lg-2 offset-md-2">
                    
                    <div class="d-flex">
                        <div class="text-left p-4 pb-2">
                            <h3 class="text-uppercase text-orange fw-bold mb-1 mt-2" style="letter-spacing: 1px">Health history</h3>
                            <p class="mb-2">We'll go over your medication and health history</p>                           
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <status v-model:status_data=status_data></status>
                            </div>
                        </div>
                    </div>

                    <form id="form" action="#" @submit.prevent="handleSubmit()">
                        <div class="border-bottom-0 p-4 pt-2">
                            <div class="form-row">
                                <label class="mb-2 fw-bold" for="if_allergies">Do you have any Allergies?</label>
                                <div class="input-group">
                                    <select v-model="data.if_allergies" id="if_allergies" class="form-select" @change="clearField('if_allergies', 'allergies')">  
                                        <option :value="true">Yes</option>
                                        <option :value ="false">No</option>
                                    </select>
                                </div>
                                <small v-if="errors.if_allergies" class="text-danger">
                                    <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    {{ errors.if_allergies }}
                                </small>
                            </div>

                            <div class="form-row mt-4" v-if="data.if_allergies">
                                <label class="fw-bold" for="allergies">List all your allergies here</label>
                                <div class="d-block mb-2">
                                    <small>Separate your allergies with commas</small>
                                </div>
                                
                                <div class="input-group">
                                    <textarea v-model="data.allergies" id="allergies" rows="5" class="form-control"></textarea>
                                </div>

                                <small v-if="errors.allergies" class="text-danger">
                                    <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    {{ errors.allergies }}
                                </small>
                            </div>

                            <div class="form-row mt-4">
                                <label class="mb-2 fw-bold" for="if_conditions">Do you have any Health Conditions?</label>
                                <div class="input-group">
                                    <select v-model="data.if_conditions" id="if_conditions" class="form-select" @change="clearField('if_conditions', 'conditions')">  
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                </div>
                                <small v-if="errors.if_conditions" class="text-danger">
                                    <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    {{ errors.if_conditions }}
                                </small>
                            </div>

                            <div class="form-row mt-4" v-if="data.if_conditions">
                                <label class="fw-bold" for="conditions">List all your health conditions here</label>
                                <div class="d-block mb-2">
                                    <small>Separate your conditions with commas</small>
                                </div>
                                
                                <div class="input-group">
                                    <textarea v-model="data.conditions" id="conditions" rows="5" class="form-control"></textarea>
                                </div>

                                <small v-if="errors.conditions" class="text-danger">
                                    <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    {{ errors.conditions }}
                                </small>
                            </div>                            

                            <div class="form-row mt-4">
                                <label class="mb-2 fw-bold" for="if_medication">Are you taking any medications, vitamins, or supplements?</label>
                                <div class="input-group">
                                    <select v-model="data.if_medication" id="if_medication" class="form-select" @change="clearField('if_medication', 'medication')">
                                        <option :value="true">Yes</option>
                                        <option :value="false">No</option>
                                    </select>
                                </div>
                                <small v-if="errors.if_medication" class="text-danger">
                                    <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    {{ errors.if_medication }}
                                </small>
                            </div>

                            <div class="form-row mt-4" v-if="data.if_medication">
                                <label class="fw-bold" for="medication">List all your medications, vitamins, or supplements here</label>
                                <div class="d-block mb-2">
                                    <small>Separate them with commas</small>
                                </div>
                                
                                <div class="input-group">
                                    <textarea v-model="data.medication" name="" id="medication" rows="5" class="form-control"></textarea>
                                </div>

                                <small v-if="errors.medication" class="text-danger">
                                    <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    {{ errors.medication }}
                                </small>
                            </div>

                            <div class="row" id="login">
                                <div class="col">
                                    <button class="btn bg-orange text-white  btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                        <span style="letter-spacing: 2px">Log Medical history</span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'shield-virus']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '../../assets/scss/base/_base.scss';
    @import '../../assets/scss/base/_fonts.scss';
    @import '../../assets/scss/helpers/breakpoints.scss';

    .card {
        box-shadow: 0 4px 8px rgb(0 0 0 / 3%)
    }
    
    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    }    
    
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'Login',
        components: {
            Status,
        },
        props: {
            user_id: {
                required: true, 
                type: Number
            }
        },
        data() {
            return {
                data: {
                    if_allergies: null,
                    allergies: null, 
                    conditions: null, 
                    if_conditions: null,
                    medication: null,
                    if_medication: null,
                    user_id: null
                },
                status_data: {
                    "success": false,
                    "success_message": null,
                    "error": false,
                    "error_message": null,
                },
                errors: [],
                eye: {
                    'password': 'eye',
                    'cpassword': 'eye'  
                },
                loading: false
            }
        },
        methods: {
            clearField(current_value, value) {
                if( !this.data[current_value]) {
                    this.data[value] = null
                }
            },
            handleSubmit() {
                this.status_data.loading = true
                this.data.user_id = this.user_id
                
                this.axios.post('api/ailness-checkup', this.data).then(response => {
                    console.log(response);
                        
                    this.status_data.loading = false
                    this.status_data.success = true
                    this.status_data.error = false
                    this.status_data.success_message = 'New Account Registered!'

                    setTimeout(() => {
                        this.$router.push({name: 'Login'});
                    }, 2000);
                }).catch((errors) => {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true
                    this.status_data.error_message = 'Errors found!'
                    this.errors = errors.data

                    console.log(errors);
                })
                
            }
        }
    }
</script>